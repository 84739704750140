import React, { useState, useEffect, useRef, useCallback } from "react";
import './dropdown.scss'

interface DropdownProps {
	className?: string;
	dropDownItems: Array<string | number>;
	value: string | number;
	onChange: (value: string | number) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ className, dropDownItems, value, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (
				dropdownRef.current &&
				event?.target &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const toggleDropdown = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event?.stopPropagation()
		setIsOpen(!isOpen);
	};

    const handleChange = useCallback((value: string|number)=>{
        setIsOpen(false)
        onChange(value)
    },[onChange])

	return (
		<div className={`dropdown${className? " "+className: ""}`} ref={dropdownRef}>
			<button onClick={toggleDropdown} className="btn dropdown-value">{value}</button>
			{isOpen && (
				<ul className="menus">
					{/* Dropdown content goes here */}
					{dropDownItems.map((item) => (
						<li className="menu" onClick={() => handleChange(item)} key={item}>{item}</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Dropdown;
