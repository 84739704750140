import { useEffect, useState } from "react";

const useVoiceInput = () => {
	const [listening, setListening] = useState<boolean>(false);
	const [transcript, setTranscript] = useState<string>("");
	const [isAudioEnded, setIsAudioEnded] = useState<boolean>(false);

	try {
		const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition;

		const recognition = new Recognition();
		if (recognition) {
			recognition.lang = "en-US";
			recognition.continuous = false;
			recognition.interimResults = false;

			recognition.onresult = (event) => {
				const transcript = event.results?.[0][0].transcript;
				setTranscript(transcript);
			};

			recognition.onend = (event) => {
				setListening(false);
				setIsAudioEnded(true);
			};

			recognition.onerror = (event) => {
				console.error("Speech recognition error:", event.error);
			};

			const startListening = () => {
				recognition.start();
				setListening(true);
			};

			const stopListening = () => {
				recognition.stop();
				setListening(false);
			};

			return {
				listening,
				transcript,
				startListening,
				stopListening,
				setTranscript
			};
		}
	} catch (error) {
		console.log("Speech Not supported");
	}

	alert("Speech recognition is not supported in this browser");
	return null;
};

export default useVoiceInput;
