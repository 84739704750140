import { useEffect } from "react";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";

interface VideoReplyProps extends ReplyTypeProps {
	connectorName?: string
}

const VideoReply: React.FC<VideoReplyProps> = ({ onSubmit, onChange, stage, connectorName }) => {
	useEffect(() => {
		onChange?.("video" + stage);
	}, [onChange, stage]);

	return (
		<button onClick={onSubmit} className="btn dark-btn">
			{connectorName ? connectorName : "Next"}
		</button>
	);
};

export default VideoReply;
