interface ConfirmPopUpProps {
	className?: string;
	onSubmit: (action: "yes" | "no") => void;
}

const ConfirmPopUp: React.FC<ConfirmPopUpProps> = ({ onSubmit }) => {
	return (
		<div className="confirm-pop-up">
			<h3>Are you sure want to go back to home?</h3>
			<div className="d-flex-center">
				<button onClick={() => onSubmit("yes")} className="btn dark-btn">Yes</button>
				<button onClick={() => onSubmit("no")} className="btn dark-btn-rounded">No</button>
			</div>
		</div>
	);
};

export default ConfirmPopUp;
