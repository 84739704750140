import React, {
  KeyboardEvent,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import { ReplyTypeProps } from "./data";
import Modal from "./Model";
import "./replyType.scss";
import "./Model.css"; // Styles for the modal
import { CITYDB, DEGREEDB } from "./ArrayDataCollection";
interface ButtonReplyProps extends ReplyTypeProps {
  buttons?: Array<string>;
}

const ButtonReply: React.FC<ButtonReplyProps> = ({
  className,
  value,
  onChange,
  buttons,
  onSubmit,
  label,
  labelheader,
  ModelType,
  isModel,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectDB, setselectDB] = useState<string[]>([]);
  const [showModel, setShowModel] = useState<any>(false);
  useEffect(() => {
    setOptionSelected(false);
    setselectDB([]);
    setQuery("");
    setSuggestions([]);
    if (ModelType === "CITY") {
      setselectDB(cities);
    } else {
      setselectDB(degree);
    }

    setShowModel(isModel.toString());
  }, [ModelType, isModel]); // Added ModelType and isModel to the dependency array

  // Add a cleanup function to reset state when the component unmounts
  useEffect(() => {
    return () => {
      setOptionSelected(false);
      setSelectedOption(null);
      setQuery("");
      setSuggestions([]);
      setIsModalOpen(false);
    };
  }, []);

  const cities: string[] = CITYDB;
  const degree: string[] = DEGREEDB;

  //Single Filter
  //==========================================================================//
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      const filteredSuggestions = selectDB.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setQuery(suggestion);
    setSuggestions([]);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const [isOptionSelected, setOptionSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const OnChooseandClose = (item: any) => {
    console.log("item", item);
    onChange?.(item);
    setSelectedOption(item);
    setIsModalOpen(false);
    setOptionSelected(true);
  };

  const myOnSubmit = () => {
    setOptionSelected(false);
    setQuery("");
    onSubmit();
  };

  return (
    <div className={`form-group ${className ? className : ""}`}>
      <label style={{ fontSize: 12, marginBottom: 30 }} className="">
        {labelheader}
      </label>
      <label>{label}</label>
      <div className="form-control">
        {isOptionSelected ? (
          <>
            <button className="btn btn-option selected">
              {selectedOption}
            </button>
          </>
        ) : (
          <>
            {buttons?.map((btn) => (
              <button
                key={btn}
                onClick={() => onChange?.(btn)}
                className={`btn btn-option${value === btn ? " selected" : ""}`}
              >
                {btn}
              </button>
            ))}
          </>
        )}
        {showModel == "true" ? (
          <button className="btn btn-option" onClick={openModal}>
            Choose another {ModelType == "CITY" ? <>City</> : <>Degree</>}
          </button>
        ) : null}
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <>
              <h2 style={{ margin: 7 }}>
                Search your {ModelType == "CITY" ? <>City</> : <>Degree</>}
              </h2>
              <div
                style={{ width: "335px", maxHeight: "300px", margin: "auto" }}
              >
                <input
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    boxSizing: "border-box",
                  }}
                  placeholder="Search..."
                />
                {suggestions.length > 0 && (
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: "0",
                      margin: "0",
                      border: "1px solid #ccc",
                      maxHeight: "250px",
                      overflowY: "auto", // Adds scrollbar when content exceeds maxHeight
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            paddingLeft: 8,
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                          className="suggestiondiv"
                          key={suggestion}
                          onClick={() => OnChooseandClose?.(suggestion)}
                          // className={`btn btn-option${
                          //   value === suggestion ? " selected" : ""
                          // }`}
                        >
                          {suggestion}
                          <hr style={{ margin: 0 }} />
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>

            <button
              style={{ marginTop: 8 }}
              className="hny-option-btn-model"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="form-control">
        <button
          className="btn btn-rounded submit"
          onClick={myOnSubmit}
          onKeyDown={onEnter}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ButtonReply;
