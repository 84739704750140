import Thumbnail from "./Thumbnail";
import { VoiceInput } from "../VoiceInput";

interface ThumbnailIntroProps {
	className?: string;
	imageURL: string;
	introMessage?: string;
	brandName?: string;
	onAudioEnd: (audioText?: string) => void;
	loading: boolean
}

const ThumbnailIntro: React.FC<ThumbnailIntroProps> = ({
	imageURL,
	className,
	onAudioEnd,
	loading,
	introMessage,
	brandName
}) => {
	return (
		<Thumbnail imageURL={imageURL} className={className}>
			<div className="video-player">
				<div className="pos-rel">
					{brandName && <div className="brand-name">{brandName}</div>}
					<div className="intro-message">
						{introMessage ? (
							<h3>{introMessage}</h3>
						) : (
							<>
								<h3>Welcome to the awareness hub bot</h3>
								<h3>Have you test your skill and learn</h3>
							</>
						)}
					</div>
					<div className="btn-section">
						<VoiceInput onAudioEnd={onAudioEnd} loading={loading}/>
					</div>
				</div>
			</div>
		</Thumbnail>
	);
};

export default ThumbnailIntro;
