import { Icon } from "../types/app.data";

const HomeIcon: React.FC<Icon> = ({ width, height, color, className, onClick }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width={width ?? "100"}
			height={height ?? "100"}
			viewBox="0 0 24 24"
			fill={color ?? "#fff"}
			className={className ?? ""}
			onClick={onClick}
		>
			<path d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 10 21 L 10 14 L 14 14 L 14 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z"></path>
		</svg>
	);
};

export default HomeIcon;
