import { Icon } from "../types/app.data";

const ReloadIcon: React.FC<Icon> = ({ width, height, onClick, color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="-1.5 0 19 19"
			version="1.1"
			fill={color ?? "#000"}
			onClick={onClick}
		>
			<title>multimedia / 7 - multimedia, refresh, replay, video icon</title>
			<g id="Free-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1193.000000, -305.000000)" id="Group">
					<g transform="translate(1189.000000, 302.000000)" id="Shape">
						<path
							d="M12,7 C15.8659932,7 19,10.1340068 19,14 C19,17.8659932 15.8659932,21 12,21 C8.13400675,21 5,17.8659932 5,14"
							stroke={color ?? "#000000"}
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
						<path
							d="M10.8260915,10.8857148 L5.69568021,7.30477744 C5.47956961,7.17854145 5.43577155,6.93976099 5.5978545,6.77144635 C5.62566353,6.74256812 5.65860149,6.71691486 5.69568021,6.69525619 L10.8260915,3.11431886 C11.1502574,2.92496489 11.610137,2.97613213 11.8532614,3.2286041 C11.948511,3.32751578 12,3.4478202 12,3.5714598 L12,10.4285738 C12,10.7441638 11.6715145,11 11.2663072,11 C11.1075579,11 10.9530909,10.9598985 10.8260915,10.8857148 Z"
							fill={color ?? "#000000"}
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ReloadIcon;
