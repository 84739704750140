import { useCallback, KeyboardEvent, useEffect, useState } from "react";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";

interface DescriptionReplyProps extends ReplyTypeProps {
  buttons?: Array<string>;
  collectiveIntelligenceData?: Array<number>;
  options?: Array<string>;
  collectiveIntelligence?: "yes" | "no";
  relatedStage?: string;
  quizData?: Array<{ stage?: string; scored?: number; actualScore?: number }>;
}

const DescriptionReply: React.FC<DescriptionReplyProps> = ({
  className,
  label,
  options,
  onSubmit,
  onChange,
  value,
  collectiveIntelligenceData,
  stage,
  collectiveIntelligence,
  relatedStage,
  quizData,
}) => {
  const [textMessage, setTextMessage] = useState<string>("");
  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLButtonElement>) => {
      if (event?.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  useEffect(() => {
    onChange?.("description" + stage);
    const currentStageAnswer = quizData?.filter(
      (item) => item.stage === relatedStage
    );
    if (currentStageAnswer?.length) {
      if (
        currentStageAnswer[0].scored !== undefined &&
        currentStageAnswer[0].scored > 0
      ) {
        setTextMessage("Yes, you are correct" + (label ? ", " + label : "."));
      } else {
        setTextMessage("No, you are incorrect" + (label ? ", " + label : "."));
      }
    } else {
      setTextMessage(label);
    }
  }, [onChange, stage, relatedStage, label, quizData]);

  return (
    <div
      className={`form-group description ${className ? " " + className : ""}`}
    >
      <label>{textMessage}</label>
      {collectiveIntelligence?.toLowerCase() === "yes" && (
        <div className="form-control">
          {options?.map((btn, index) => (
            <button
              key={btn}
              onClick={() => false}
              className={`btn btn-option${value === btn ? " selected" : ""}`}
            >
              {btn}
              {collectiveIntelligenceData?.length && (
                <span
                  className="text-layer"
                  style={{ width: `${collectiveIntelligenceData[index]}%` }}
                ></span>
              )}
            </button>
          ))}
        </div>
      )}
      <div className="form-control">
        <button
          className="btn btn-rounded submit"
          onClick={onSubmit}
          onKeyDown={onEnter}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DescriptionReply;
