import { useCallback, KeyboardEvent, useMemo, useState } from "react";
import Send from "../../icons/Send";
import { ReplyTypeProps } from "./data";
import { useInput } from "./useInput";
import "./replyType.scss";

const mobileNumberRegex =
  /^(\d{10}|\d{3}[-.\s]\d{3}[-.\s]\d{4}|\(\d{3}\)[-.\s]\d{3}[-.\s]\d{4})$/;
const skipRegex = /^skip$/i

const MobileSkipReply: React.FC<ReplyTypeProps> = ({
  className,
  onChange,
  value,
  onSubmit,
  label,
}) => {
  const { isFocused, onBlur, onFocus } = useInput();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const validateMobile = useCallback(() => {
    let errorMessage = "";
    if (value){
      if(mobileNumberRegex.test(value)){
        console.log("Matched number")
        errorMessage = "";
      }
      else if(skipRegex.test(value)){
        console.log("MAtched SKIP")
        errorMessage = "";
      }
      else{
        console.log("IN ELSEEEEEEEEEEEEEEEEEE")
        errorMessage = "Please enter 10 digit mobile number or write skip if you want to skip this.";
      }
    }
    return errorMessage;
  }, [value]);

  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === "Enter") {
        const isValidMobile = validateMobile();
        if (isValidMobile) {
          setErrorMessage(isValidMobile);
        } else {
          onSubmit?.();
          setErrorMessage("");
        }
      }
    },
    [onSubmit, validateMobile]
  );

  const onSend = useCallback(() => {
    const isValidMobile = validateMobile();
    //console.log("isValidMobile", isValidMobile);
    //console.log("value", value);
    if (!isValidMobile) {
      const myHeaders: Headers = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3NzUzZGE3Ny01YTE5LTRjYjktOTVhYi1mYjdkMDcyMjI2ZTAiLCJ1bmlxdWVfbmFtZSI6InN1cHBvcnRAaGFwcHlncmVlbmVjb25vbXkuaW4iLCJuYW1laWQiOiJzdXBwb3J0QGhhcHB5Z3JlZW5lY29ub215LmluIiwiZW1haWwiOiJzdXBwb3J0QGhhcHB5Z3JlZW5lY29ub215LmluIiwiYXV0aF90aW1lIjoiMDQvMDkvMjAyNCAxODowNzoyMyIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMTAwMDgiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.hp3ThXCD67XY1D4dHMHWQFCAmmwhKTSjAfAMDN4EzFw"
      );
      myHeaders.append("Content-Type", "application/json");

      const raw: string = JSON.stringify({
        broadcast_name: "string",
        receivers: [
          {
            customParams: [
              {
                name: "name",
                value: "Dear",
              },
            ],
            whatsappNumber: `91${value}`,
          },
        ],
        template_name: "welcome_lsb",
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://live-mt-server.wati.io/310008/api/v1/sendTemplateMessages",
        requestOptions
      )
        .then((response: Response) => response.text())
        .then((result: string) => {
          console.log(result);
          onSubmit?.();
        })
        .catch((error: any) => console.error(error));

      setErrorMessage("");
    } else {
      setErrorMessage(isValidMobile);
    }
  }, [validateMobile, onSubmit]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
      setErrorMessage("");
    },
    [onChange]
  );

  return (
    <div className={`form-group${className ? " " + className : ""}`}>
      <div className="form-control text-a-left">
        <div className="input-box">
          <label
            className={`${
              isFocused ? "focus" : value ? "focus" : "not-focused"
            }`}
          >
            {label}
          </label>
          <input
            type="text"
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            max={10}
            className={`${errorMessage ? "error" : ""}`}
            onKeyDown={onEnter}
            placeholder="Enter here..."
          />
          <Send width={20} height={20} color="#fff" onClick={onSend} />
        </div>
        {errorMessage && <p className="error-msg">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default MobileSkipReply;
