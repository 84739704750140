import { useCallback, KeyboardEvent, useMemo, useState } from "react";
import Send from "../../icons/Send";
import { ReplyTypeProps } from "./data";
import { useInput } from "./useInput";
import "./replyType.scss";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const EmailReply: React.FC<ReplyTypeProps> = ({ className, onChange, value, onSubmit, label }) => {
	const { isFocused, onBlur, onFocus } = useInput();
	const [errorMessage, setErrorMessage] = useState<string>("");

	const validateEmail = useCallback(() => {
		let errorMessage = "";
		if (value && !emailRegex.test(value?.trim())) {
			errorMessage = "Please enter valid email";
		}
		return errorMessage;
	}, [value]);

	const onEnter = useCallback(
		(event?: KeyboardEvent<HTMLInputElement>) => {
			if (event?.key === "Enter") {
				const validEmail = validateEmail();
				if (validEmail) {
					setErrorMessage(validEmail);
				} else {
					setErrorMessage("");
					onSubmit?.();
				}
			}
		},
		[validateEmail, onSubmit]
	);

	const onSend = useCallback(() => {
		const validEmail = validateEmail();
		if (!validEmail) {
			console.log("submit");
			onSubmit?.();
			setErrorMessage("");
		} else {
			setErrorMessage(validEmail);
		}
	}, [validateEmail, onSubmit]);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChange?.(event.target.value);
			setErrorMessage("");
		},
		[onChange]
	);

	return (
		<div className={`form-group${className ? " " + className : ""}`}>
			<div className="form-control text-a-left">
				<div className="input-box">
					<label className={`${isFocused ? "focus" : value ? "focus" : "not-focused"}`}>
						{label}
					</label>
					<input
						type="text"
						value={value}
						onChange={handleChange}
						onFocus={onFocus}
						onBlur={onBlur}
						className={`${errorMessage ? "error" : ""}`}
						onKeyDown={onEnter}
						placeholder="Enter here..."
					/>
					<Send width={20} height={20} color="#fff" onClick={onSend} />
				</div>
				{errorMessage && <p className="error-msg">{errorMessage}</p>}
			</div>
		</div>
	);
};

export default EmailReply;
