import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Play } from "../../icons";
import FbIcon from "../../icons/FbIcon";
import InstaIcon from "../../icons/InstaIcon";
import LinkedInIcon from "../../icons/LinkedInIcon";
import MessangerIcon from "../../icons/FbIcon";
import TwitterIcon from "../../icons/Twittericon";
import WAIcon from "../../icons/WAIcon";
import "./videoThumbnail.scss";
import { ProjectDataContext } from "../ChatBox";

interface VideoThumbnailProps {
  thumbnail?: string;
  handleSubmit: () => void;
  title?: string;
  brandName?: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  thumbnail,
  handleSubmit,
  title,
  brandName,
}) => {
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const shareUrl = useRef(window.location.href);
  const { projectData } = useContext(ProjectDataContext);
  console.log("projectData", projectData);
  const shareOnFacebook = useCallback(() => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl.current
      )}`
    );
    setShowIcons(false);
  }, []);

  const shareOnWhatsapp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareUrl.current
      )}`
    );
    setShowIcons(false);
  }, []);

  const shareOnInstagram = useCallback(() => {
    window.open(
      `https://www.instagram.com/share?url=${encodeURIComponent(
        shareUrl.current
      )}`
    );
    setShowIcons(false);
  }, []);

  const shareOnLinkedin = useCallback(() => {
    window.open(
      `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
        shareUrl.current
      )}`
    );
    setShowIcons(false);
  }, []);

  const shareOnTwitter = useCallback(() => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareUrl.current
      )}`
    );
    setShowIcons(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        event?.target &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowIcons(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    //main-thumbnail-image
    <div
      className="video-thumbnail"
      style={{ backgroundImage: `url(${thumbnail})` }}
    >
      <div className="thumbnail-content">
        <div className="header-logo-sec">
          {projectData?.logo1 && <img src={projectData?.logo1} alt="Logo" />}
          {projectData?.logo2 && <img src={projectData?.logo2} alt="Logo" />}
        </div>
        <div className="play-sec">
          <div className="rounded-shadow sonar-wrapper" onClick={handleSubmit}>
            <div className="sonar-emitter">
              <Play width={100} height={100} />
              <div className="sonar-wave"></div>
            </div>
          </div>
        </div>
        <div className="thumbnail-title">
          <div className="share-sec">
            {title && <h3 className="title">{title}</h3>}

            <div className="d-flex-center d-j-s p-rel">
              <h3 className="brand">{brandName}</h3>

              <button
                className="btn btn-rounded"
                onClick={() => setShowIcons(true)}
              >
                Share
              </button>
              <div
                className={`d-flex-center social-icons${
                  showIcons ? " show" : ""
                }`}
                ref={dropdownRef}
              >
                <WAIcon
                  width={30}
                  height={35}
                  color="#fff"
                  onClick={shareOnWhatsapp}
                />
                <InstaIcon
                  width={30}
                  height={35}
                  color="#fff"
                  onClick={shareOnInstagram}
                />
                <MessangerIcon
                  width={30}
                  height={35}
                  color="#000"
                  onClick={shareOnFacebook}
                />
                <TwitterIcon
                  width={30}
                  height={35}
                  color="#fff"
                  onClick={shareOnTwitter}
                />
                <LinkedInIcon
                  width={30}
                  height={35}
                  color="#fff"
                  onClick={shareOnLinkedin}
                />
              </div>
            </div>
          </div>
          <p
            className="subtext"
            style={{ fontSize: 14, margin: 0, paddingTop: 3 }}
          >
            {projectData?.tNcText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoThumbnail;
