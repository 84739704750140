import { useCallback, KeyboardEvent, useEffect } from "react";
import Send from "../../icons/Send";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";
import { useInput } from "./useInput";
// import useSpeechRecognition from "./useSpeechRecognition";
import VoiceRecorder from "./VoiceRecorder";
// import img from "../img/mic-svgrepo-com.svg";
// import micIcon from '../../icons/animated mic.gif'; // import your SVG file
// import micStopIcon from '../../icons/mic-svgrepo-com stop.svg'; // import your SVG file

import { useRef, useState } from "react";
import animatedMic from "../../icons/animated mic.gif"; // import your GIF file
import micIcon from "../../icons/mic3.png"; // import your PNG file

const VoiceInputReply: React.FC<ReplyTypeProps> = ({
  className,
  onChange,
  value,
  onSubmit,
  label,
  error,
  //setReplyAnswer,
}) => {
  const { isFocused, onBlur, onFocus } = useInput();
  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === "Enter") {
        onSubmit?.();
      }
    },
    [onSubmit]
  );

  const audioChunks = useRef<Blob[]>([]);
  const [recordings, setRecordings] = useState<string | undefined>();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [streamRecording, setStreamRecording] = useState<boolean>(false);
  const [showAudio, setShowAudio] = useState<boolean>(false);
  const [showStartbtn, setshowStartBtn] = useState<boolean>(true);
  const [permission, setPermission] = useState(false);

  const [audioDuration, setAudioDuration] = useState<number>(0);
  const [audioCurrentTime, setAudioCurrentTime] = useState<number>(0);

  const startRec = async () => {
    try {
      audioChunks.current = []; // Clear previous chunks
      //console.log("called startRec");

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (e) => {
        //console.log("called ondataavailable");
        if (e.data.size > 0) {
          audioChunks.current.push(e.data);
        }
      };
      mediaRecorder.onstop = () => {
        console.log("called onstop");
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);
        setRecordings(audioUrl);
        //onChange?.(audioBlob);
      };
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setStreamRecording(true);
    } catch (e) {
      console.error("Err accessing MIC", e);
    }
  };

  const stopRec = async () => {
    console.log("called stop");
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setshowStartBtn(false);
      setStreamRecording(false);
      setShowAudio(true);
    }
  };

  const handleSubmit = async () => {
    if (audioChunks.current.length > 0) {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      const fileName = "my_voice.wav";
      const voiceName = "userVoice";
      await submitRecording(audioBlob, fileName, voiceName)
        .then(async (res) => {
          console.log("res", res);
          //localStorage.setItem("audiores", res);
          if (res) {
            //setReplyAnswer(res);
          }

          //onChange?.(res);
          // Wait for the onChange callback to complete
          //await new Promise((resolve) => setTimeout(resolve, 0));

          // Now, call onSubmit after the onChange callback has completed
          onSubmit?.();
        })
        .catch((err) => {
          console.log("Err", err);
        });
    } else {
      console.error("No recording available to submit.");
    }
  };

  const submitRecording = async (
    audioBlob: Blob,
    fileName: string,
    voiceName: string
  ) => {
    try {
      const formdata = new FormData();
      formdata.append("sample_file", audioBlob, fileName);
      formdata.append("voice_name", voiceName);

      const requestOptions: RequestInit = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        "https://hnylearning.com/awarenessHubBackend/voiceProcessApi/",
        requestOptions
      );
      const result = await response.text();
      onChange?.(result);
      localStorage.setItem("audioResp", result);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`form-group${className ? " " + className : ""}`}>
      <div className={`form-control text-a-left${error ? " error" : ""}`}>
        <div className="input-box ">
          {!showStartbtn ? (
            <label
              style={{ bottom: 140, marginBottom: 90 }}
              className={`voice-label ${
                isFocused ? "focus" : value ? "focus" : "not-focused"
              }`}
            >
              {label}
            </label>
          ) : (
            <label
              style={{ marginBottom: streamRecording ? 150 : 150 }}
              className={`voice-label ${
                isFocused ? "focus" : value ? "focus" : "not-focused"
              }`}
            >
              {label}
            </label>
          )}
          <>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {!streamRecording ? (
                <>
                  {showStartbtn ? (
                    <>
                      <img
                        src={micIcon}
                        alt="Microphone"
                        width="110px"
                        height="110px"
                        onClick={startRec}
                      />
                      <p style={{ color: "white" }}>Tap to start</p>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <img
                    src={animatedMic}
                    alt="Microphone"
                    width="110px"
                    height="110px"
                    onClick={stopRec}
                  />
                  <p style={{ color: "white" }}>Tap to stop</p>
                </>
              )}
              <br />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              {showAudio && !streamRecording ? (
                <div
                  className="btn btn-rounded"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ marginRight: "10px" }}>Preview</span>
                  <audio controls src={recordings} />
                </div>
              ) : null}
            </div>
            {!streamRecording && !showStartbtn ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <button className="btn btn-rounded" onClick={handleSubmit}>
                    Confirm and Submit
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <button className="btn btn-rounded" onClick={startRec}>
                    Record Again
                  </button>{" "}
                  <br />
                </div>
              </>
            ) : null}
          </>
        </div>
        {error && <p className="error-msg">{error}</p>}
      </div>
    </div>
  );
};

export default VoiceInputReply;
