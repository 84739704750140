import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./videoPlayer.scss";
import Play from "../../icons/Play";
import Pause from "../../icons/Pause";
import { Journey } from "../data";
import Prev from "../../icons/Prev";
import HomeIcon from "../../icons/HomeIcon";
import VideoReply from "../ReplyType/VideoReply";
import Unmute from "../../icons/UnmuteIcon";
import ReloadIcon from "../../icons/ReloadIcon";
import { VideoPlayPopUp } from "./VideoPlayPopUp";
import { JourneyReplyType } from "../JourneyReplyType";
import { Dropdown } from "../Dropdown";

interface VideoPlayerProps {
  className?: string;
  journeyData: Journey | null;
  onAudioEnd: (audioText?: string) => void;
  handleNextStage: (stage?: string) => void;
  handlePrevStage: (stage?: string) => void;
  isPrevVisible: boolean;
  thumbnail?: string;
  backToHome?: () => void;
  replyAnswer: string;
  setReplyAnswer: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  showPopUp: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  className,
  journeyData,
  onAudioEnd,
  handleNextStage,
  handlePrevStage,
  isPrevVisible,
  thumbnail,
  backToHome,
  replyAnswer,
  setReplyAnswer,
  loading,
  showPopUp,
  setShowPopUp,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);
  const [mute, setMute] = useState<boolean>(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [speedValue, setSpeedValue] = useState<string | number>("1.0");

  const togglePlay = () => {
    if (videoRef?.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoEnded(false);
      setIsPlaying(!isPlaying);
    }
  };
  const onSpeedChange = useCallback((value: string | number) => {
    setSpeedValue(value);
    if (videoRef?.current) {
      videoRef.current.playbackRate = parseFloat(value as string);
    }
  }, []);

  const toggleMute = useCallback(
    (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event?.stopPropagation();
      setMute((prev) => !prev);
    },
    []
  );

  const handleTimeUpdate = () => {
    if (videoRef?.current) {
      setProgress(
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      );
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef?.current) {
      const time =
        (parseFloat(e.target.value) / 100) * videoRef.current.duration;
      videoRef.current.currentTime = time;
    }
  };

  const onVideoEnd = () => {
    setIsPlaying(false);
    setIsVideoEnded(true);
  };

  const onAnswerChange = useCallback(
    (answer: string) => {
      setReplyAnswer(answer);
      if (answer?.trim()) {
        setError("");
      }
    },
    [setReplyAnswer]
  );

  const onSubmit = useCallback(() => {
    setIsVideoEnded(true);
    setIsPlaying(false);
    if (replyAnswer?.trim()) {
      handleNextStage(journeyData?.stage);
      setSpeedValue("1.0");
      setProgress(0);
      setReplyAnswer("");
      setError("");
    } else {
      setError("Invalid Input");
    }
  }, [replyAnswer, handleNextStage, journeyData?.stage, setReplyAnswer]);

  useEffect(() => {
    if (journeyData?.video) {
      setIsVideoEnded(false);
      setProgress(0);
    } else {
      setIsVideoEnded(true);
    }
  }, [journeyData?.video]);

  const onPrevClick = useCallback(() => {
    handlePrevStage(journeyData?.stage);
    setReplyAnswer("");
  }, [handlePrevStage, journeyData?.stage, setReplyAnswer]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Add an event listener for the 'canplaythrough' event
      videoElement.addEventListener("canplaythrough", () => {
        // Video is fully loaded
        setVideoLoaded(true);
      });
    }

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      if (videoElement) {
        videoElement.removeEventListener("canplaythrough", () => {
          // Do nothing
        });
      }
    };
  }, [journeyData?.video]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoLoaded && videoElement && !showPopUp) {
      // Auto play the video when it's fully loaded
      setTimeout(() => {
        setIsAutoPlay(true);
        videoElement.play();
        setIsVideoEnded(false);
        setIsPlaying(true);
      }, 500);
    }
  }, [videoLoaded, journeyData?.video, showPopUp]);

  const hideVideoControls = useMemo(() => {
    return !(!!journeyData?.ReplyType && isVideoEnded);
  }, [journeyData?.ReplyType, isVideoEnded]);

  const handleSubmit = useCallback(() => {
    setShowPopUp(false);
    if (videoRef.current) {
      setIsAutoPlay(true);
      videoRef.current.play();
      setIsVideoEnded(false);
      setIsPlaying(true);
    }
  }, [setShowPopUp]);

  const onCancel = useCallback(() => {
    setShowPopUp(false);
    if (videoRef.current) {
      setIsAutoPlay(true);
      videoRef.current.play();
      setIsVideoEnded(false);
      setIsPlaying(true);
    }
  }, [setShowPopUp]);

  const handleProgressClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  return (
    <div className={`video-player${className ? " " + className : ""}`}>
      <div className="video-container">
        {journeyData?.video && (
          <video
            onTimeUpdate={handleTimeUpdate}
            ref={videoRef}
            onEnded={onVideoEnd}
            key={journeyData.video}
            controls={false}
            playsInline
            autoPlay={isAutoPlay}
            muted={mute}
          >
            <source id="start" type="video/mp4" src={journeyData.video} />
            Your browser does not support the video.
          </video>
        )}
      </div>
      <>
        {showPopUp
          ? journeyData?.video && (
              <VideoPlayPopUp onCancel={onCancel} handleSubmit={handleSubmit} />
            )
          : null}
      </>
      <div className={`video-upper-layer${!isPlaying ? " bg" : ""}`}>
        <div
          className={`video-controls${journeyData?.video ? "" : " bg"}`}
          style={{
            backgroundImage: `${
              !isPlaying
                ? journeyData?.video
                  ? "none"
                  : `url(${thumbnail})`
                : "none"
            }`,
          }}
        >
          <HomeIcon
            width={30}
            height={30}
            color="#fff"
            className="home-ic"
            onClick={backToHome}
          />
          <div className="upper">
            {isPrevVisible && (
              <Prev
                width={40}
                height={40}
                color="#fff"
                onClick={onPrevClick}
                className="prev-ic"
              />
            )}
          </div>
          {journeyData?.video && (
            <div className="middle" onClick={togglePlay}>
              <div className="rounded-shadow" onClick={togglePlay}>
                {progress === 100 ? (
                  <ReloadIcon width={100} height={100} color="#fff" />
                ) : (
                  <>
                    {isPlaying ? (
                      <Pause width={100} height={100} />
                    ) : (
                      <Play width={100} height={100} />
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <div className="lower">
            {isVideoEnded && (
              <div className="reply-section">
                <JourneyReplyType
                  onAudioEnd={onAudioEnd}
                  loading={loading}
                  replyAnswer={replyAnswer}
                  onAnswerChange={onAnswerChange}
                  onSubmit={onSubmit}
                  error={error}
                  setReplyAnswer={setReplyAnswer}
                  journeyData={journeyData}
                />
              </div>
            )}
            {journeyData?.video && (
              <>
                {hideVideoControls && (
                  <div className="progress-bar d-flex-center">
                    <div className="volume-sec d-flex-center">
                      <div
                        className={`speaker-ic${mute ? " mute" : ""}`}
                        onClick={toggleMute}
                      >
                        <Unmute width={30} height={40} color="#fff" />
                      </div>
                    </div>
                    <input
                      type="range"
                      className="range"
                      min="0"
                      max="100"
                      step="0.1"
                      value={progress}
                      onClick={handleProgressClick}
                      onChange={handleSeek}
                    />
                    <Dropdown
                      value={speedValue}
                      onChange={onSpeedChange}
                      dropDownItems={[
                        "0.5",
                        "0.75",
                        "1.0",
                        "1.25",
                        "1.5",
                        "1.75",
                        "2.0",
                      ]}
                      className="speed-dropdown"
                    />
                  </div>
                )}
                <div className="navigation">
                  {journeyData.ReplyType === "video" && (
                    <VideoReply
                      label={""}
                      labelheader={""}
                      ModelType={""}
                      isModel={""}
                      onSubmit={onSubmit}
                      error={""}
                      onChange={onAnswerChange}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
