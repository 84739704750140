import "./voiceInput.scss";
import { useCallback, KeyboardEvent } from "react";
import useVoiceInput from "../hooks/useVoiceInput";
import Microphone from "../../icons/Microphone";
import Send from "../../icons/Send";

interface VoiceInputProps extends React.HTMLProps<HTMLButtonElement> {
	onAudioEnd: (audioText?: string) => void;
	loading: boolean;
}

const VoiceInput: React.FC<VoiceInputProps> = ({ loading, onAudioEnd }) => {
	const voiceInput = useVoiceInput();

	const onEnter = useCallback(
		(event?: KeyboardEvent<HTMLInputElement>) => {
			if (event?.key === "Enter") {
				onAudioEnd(voiceInput?.transcript);
			}
		},
		[onAudioEnd, voiceInput?.transcript]
	);

	return (
		<div className="text-center voice-input-sec">
			<div className="d-flex-center p-rel">
				<div className="w-100 d-flex-center input-group">
					<input
						value={voiceInput?.transcript}
						onChange={(event) => voiceInput?.setTranscript(event.target.value)}
						placeholder={loading ? "" : "Type or use voice input to ask me anything ..."}
						onKeyDown={onEnter}
						className={loading ? "hide" : ""}
					/>
					<Send
						width={30}
						height={30}
						color="#fff"
						onClick={() => onAudioEnd(voiceInput?.transcript)}
						className="send-ic"
					/>
					<button
						disabled={!voiceInput}
						className={`btn voice${voiceInput?.listening ? " ripple" : ""}`}
						title={
							!voiceInput
								? "Speech recognition is not supported in this browser"
								: "Listening"
						}
						onClick={
							voiceInput?.listening
								? voiceInput?.stopListening
								: voiceInput?.startListening
						}
					>
						<Microphone width={30} height={40} color="#fff" />
					</button>
				</div>
				{loading && (
					<div className="d-flex-center w-100 loading-sec">
						<div className="loader-gif">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default VoiceInput;
