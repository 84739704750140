import { KeyboardEvent, useCallback } from "react";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";

interface QuizButtonReplyProps extends ReplyTypeProps {
  buttons?: Array<string>;
  correctAnswer?: string;
  score?: number;
  stage?: string;
  setQuizData?: React.Dispatch<
    React.SetStateAction<
      | {
          stage?: string;
          scored?: number;
          actualScore?: number;
          answer: string;
        }[]
      | undefined
    >
  >;
}

const QuizButtonReply: React.FC<QuizButtonReplyProps> = ({
  className,
  value,
  onChange,
  buttons,
  onSubmit,
  label,
  labelheader,
  correctAnswer,
  score,
  stage,
  setQuizData,
}) => {
  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLButtonElement>) => {
      if (event?.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const handleChange = useCallback(
    (index: any, value: string) => {
      onChange?.(value);
      setQuizData?.((prev) => {
        let scored: number | undefined = 0;
        let answer = "WRONG";
        if (correctAnswer === value) {
          scored = score;
          answer = "RIGHT";
        }
        const existingObjectIndex = prev?.findIndex(
          (obj) => obj.stage === stage
        );
        if (
          existingObjectIndex !== undefined &&
          existingObjectIndex !== -1 &&
          prev?.length
        ) {
          prev[existingObjectIndex].scored = scored;
          prev[existingObjectIndex].actualScore = score;
          prev[existingObjectIndex].answer = answer;
          return prev;
        } else {
          const obj = {
            scored: scored,
            actualScore: score,
            stage: stage,
            answer,
            index: index,
          };
          return prev?.length ? [...prev, obj] : [obj];
        }
      });
    },
    [correctAnswer, onChange, score, setQuizData, stage]
  );

  return (
    <div className={`form-group ${className ? " " + className : ""}`}>
      <label style={{ fontSize: 12, marginBottom: 30 }} className="labelheader">
        {labelheader}
      </label>
      <label>{label}</label>
      <div className="form-control">
        {buttons?.map((btn, index) => (
          <button
            key={btn}
            onClick={() => handleChange(index, btn)} // Pass both index and button value to handleChange
            className={`btn btn-option${value === btn ? " selected" : ""}`}
          >
            {btn}
          </button>
        ))}
      </div>
      <div className="form-control">
        <button
          className="btn btn-rounded submit"
          onClick={onSubmit}
          onKeyDown={onEnter}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QuizButtonReply;
