import { VoiceInput } from "../VoiceInput";
import "./replyType.scss";

interface EndReplyProps {
  onAudioEnd: (audioText?: string) => void;
  label?: string;
  loading: boolean;
}

const EndReply: React.FC<EndReplyProps> = ({ onAudioEnd, loading, label }) => {
  return (
    <div className="end-reply">
      <h1>Hello</h1>
      {label && <label dangerouslySetInnerHTML={{ __html: label }}></label>}
      <VoiceInput onAudioEnd={onAudioEnd} loading={loading} />;
    </div>
  );
};

export default EndReply;
