import CloseIcon from "../../icons/CloseIcon";
import "./popUp.scss";

interface PopUpProps {
	className?: string;
	children: JSX.Element;
	closePopUp?: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ className, children, closePopUp }) => {
	return (
		<div className={`pop-up${className ? " " + className : ""}`}>
			<div className="pop-up-container">
				{closePopUp && (
					<div className="close">
						<CloseIcon width={20} height={20} onClick={closePopUp} />
					</div>
				)}
				{children}
			</div>
		</div>
	);
};

export default PopUp;
