import { useCallback, KeyboardEvent, useMemo } from "react";
import Send from "../../icons/Send";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";

const DateReply: React.FC<ReplyTypeProps> = ({
	className,
	onChange,
	value,
	onSubmit,
	label,
	error,
	validation
}) => {
	const onEnter = useCallback(
		(event?: KeyboardEvent<HTMLInputElement>) => {
			if (event?.key === "Enter") {
				onSubmit?.();
			}
		},
		[onSubmit]
	);

	const getCurrentDate = useCallback(() => {
		let today = new Date();
		let dd: any = today.getDate();
		let mm: any = today.getMonth() + 1;
		const yyyy = today.getFullYear();

		if (dd < 10) {
			dd = "0" + dd;
		}

		if (mm < 10) {
			mm = "0" + mm;
		}

		return yyyy + "-" + mm + "-" + dd;
	}, []);

	const dateValidation = useMemo(() => {
		const obj: {
			min?: string;
			max?: string;
		} = {
			min: undefined,
			max: undefined
		};
		if (validation === "future") {
			obj.min = getCurrentDate();
		} else if (validation === "past") {
			obj.max = getCurrentDate();
		}
		return obj;
	}, [getCurrentDate, validation]);

	return (
		<div className={`form-group${className ? " " + className : ""}`}>
			<div className="form-control text-a-left">
				<label>{label}</label>
				<div className="input-box">
					<input
						type="date"
						value={value}
						onChange={(event) => onChange?.(event.target.value)}
						placeholder="Enter here"
						className={`${error ? "error" : ""}`}
						onKeyDown={onEnter}
						{...dateValidation}
					/>
					<Send width={20} height={20} color="#fff" onClick={onSubmit} />
				</div>
				{error && <p className="error-msg">{error}</p>}
			</div>
		</div>
	);
};

export default DateReply;
