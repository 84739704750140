export const CITYDB: string[] = [
  "Agartala",
  "Agra",
  "Ahmedabad",
  "Aizawl",
  "Ajmer",
  "Akola",
  "Aligarh",
  "Allahabad",
  "Alwar",
  "Ambala",
  "Ambattur",
  "Amravati",
  "Amritsar",
  "Anand",
  "Anantapur",
  "Arrah",
  "Asansol",
  "Aurangabad",
  "Aurangabad",
  "Bangalore",
  "Bareilly",
  "Bathinda",
  "Belgaum",
  "Bellary",
  "Bhadohi",
  "Bhagalpur",
  "Bharatpur",
  "Bhatpara",
  "Bhavnagar",
  "Bhilai",
  "Bhilwara",
  "Bhiwandi",
  "Bhopal",
  "Bhubaneswar",
  "Bhuj",
  "Bikaner",
  "Bilaspur",
  "Bokaro",
  "Chandigarh",
  "Chennai",
  "Coimbatore",
  "Cuttack",
  "Davanagere",
  "Dehradun",
  "Delhi",
  "Dhanbad",
  "Dibrugarh",
  "Durgapur",
  "Eluru",
  "Erode",
  "Faridabad",
  "Firozabad",
  "Gandhinagar",
  "Ghaziabad",
  "Gorakhpur",
  "Gulbarga",
  "Guntur",
  "Gurgaon",
  "Guwahati",
  "Gwalior",
  "Haldia",
  "Hapur",
  "Haridwar",
  "Hisar",
  "Hubballi-Dharwad",
  "Hyderabad",
  "Imphal",
  "Indore",
  "Jabalpur",
  "Jaipur",
  "Jalandhar",
  "Jalgaon",
  "Jammu",
  "Jamnagar",
  "Jamshedpur",
  "Jhansi",
  "Jodhpur",
  "Junagadh",
  "Kakinada",
  "Kalyan-Dombivli",
  "Kanpur",
  "Karnal",
  "Karur",
  "Kochi",
  "Kolhapur",
  "Kolkata",
  "Kollam",
  "Kota",
  "Kozhikode",
  "Kurnool",
  "Latur",
  "Loni",
  "Lucknow",
  "Ludhiana",
  "Madurai",
  "Maheshtala",
  "Malappuram",
  "Malegaon",
  "Mangalore",
  "Mathura",
  "Meerut",
  "Moradabad",
  "Mumbai",
  "Mysore",
  "Nadiad",
  "Nagercoil",
  "Nagpur",
  "Naihati",
  "Nanded",
  "Nashik",
  "Navi Mumbai",
  "Nellore",
  "New Delhi",
  "Noida",
  "Pallavaram",
  "Palwal",
  "Panaji",
  "Panchkula",
  "Panihati",
  "Panipat",
  "Parbhani",
  "Patiala",
  "Patna",
  "Pimpri-Chinchwad",
  "Pondicherry",
  "Pune",
  "Puri",
  "Raipur",
  "Rajahmundry",
  "Rajkot",
  "Rajpur Sonarpur",
  "Ranchi",
  "Ratlam",
  "Rewa",
  "Rohtak",
  "Rourkela",
  "Sagar",
  "Saharanpur",
  "Salem",
  "Sambalpur",
  "Sangli-Miraj & Kupwad",
  "Satara",
  "Satna",
  "Secunderabad",
  "Shivamogga",
  "Siliguri",
  "Solapur",
  "Sonipat",
  "South Dumdum",
  "Srinagar",
  "Surat",
  "Thane",
  "Thiruvananthapuram",
  "Thoothukudi",
  "Tiruchirappalli",
  "Tirunelveli",
  "Tiruppur",
  "Tumkur",
  "Udaipur",
  "Udupi",
  "Ujjain",
  "Ulhasnagar",
  "Vadodara",
  "Varanasi",
  "Vasai-Virar",
  "Vijayawada",
  "Visakhapatnam",
  "Warangal",
];

// interface CategoryData {
//   [key: string]: string[];
// }

// export const DEGREEDB: CategoryData = {
//   Medical: ["Cardiology", "Neurology", "Pediatrics"],
//   Engineering: ["IT", "Civil", "Mechanical", "Electrical"],
//   // Add more categories and subcategories as needed
// };

export const DEGREEDB: string[] = [
  "Diploma in Elementary Education (D.El.Ed)",
  "Bachelor of Elementary Education (B.El.Ed)",
  "Diploma in Education (D.Ed)",
  "Bachelor of Education (B.Ed)",
  "Bachelor of Arts (B.A)",
  "Bachelor of Science (B.Sc)",
  "Bachelor of Commerce (B.Com)",
  "Bachelor of Fine Arts (BFA)",
  "Bachelor of Business Administration (BBA)",
  "Bachelor of Technology (B.Tech)",
  "Bachelor of Computer Applications (BCA)",
  "Bachelor of Design (B.Des)",
  "Bachelor of Architecture (B.Arch)",
  "Bachelor of Pharmacy (B.Pharm)",
  "Bachelor of Physical Education (B.P.Ed)",
  "Master of Arts (M.A)",
  "Master of Science (M.Sc)",
  "Master of Commerce (M.Com)",
  "Master of Fine Arts (MFA)",
  "Master of Business Administration (MBA)",
  "Master of Technology (M.Tech)",
  "Master of Computer Applications (MCA)",
  "Master of Design (M.Des)",
  "Master of Architecture (M.Arch)",
  "Master of Pharmacy (M.Pharm)",
];
