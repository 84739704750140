import { useCallback, KeyboardEvent } from "react";
import Send from "../../icons/Send";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";
import { useInput } from "./useInput";

const TextReply: React.FC<ReplyTypeProps> = ({
  className,
  onChange,
  value,
  onSubmit,
  label,
  labelheader,
  error,
}) => {
  const { isFocused, onBlur, onFocus } = useInput();
  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === "Enter") {
        onSubmit?.();
      }
    },
    [onSubmit]
  );

  return (
    <div className={`form-group${className ? " " + className : ""}`}>
      <div className={`form-control text-a-left${error ? " error" : ""}`}>
        <div className="input-box">
          <label style={{ fontSize: 12, marginBottom: 90 }} className="">
            {labelheader}
          </label>
          <label
            style={{ paddingTop: 50 }}
            className={`${
              isFocused ? "focus" : value ? "focus" : "not-focused"
            }`}
          >
            {label}
          </label>
          <input
            type="text"
            value={value}
            onChange={(event) => onChange?.(event.target.value)}
            className={`${error ? "error" : ""}`}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onEnter}
            placeholder="Enter here..."
          />
          <Send width={20} height={20} color="#fff" onClick={onSubmit} />
        </div>
        {error && <p className="error-msg">{error}</p>}
      </div>
    </div>
  );
};

export default TextReply;
