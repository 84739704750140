import { Icon } from "../types/app.data";

const Microphone: React.FC<Icon> = ({className, width, height, color}) => {
	return (
		<svg
			className={className??""}
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			width={width??"256"}
			height={height??"256"}
			viewBox="0 0 256 256"
            fill={color}
		>
			<defs></defs>
			<g
				transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
			>
				<path
					d="M 45 0 c -8.481 0 -15.382 6.9 -15.382 15.382 v 29.044 c 0 8.482 6.9 15.382 15.382 15.382 s 15.382 -6.9 15.382 -15.382 V 15.382 C 60.382 6.9 53.481 0 45 0 z"
					transform=" matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 69.245 38.312 c -1.104 0 -2 0.896 -2 2 v 6.505 c 0 12.266 -9.979 22.244 -22.245 22.244 s -22.245 -9.979 -22.245 -22.244 v -6.505 c 0 -1.104 -0.896 -2 -2 -2 s -2 0.896 -2 2 v 6.505 c 0 13.797 10.705 25.134 24.245 26.16 V 86 h -9.126 c -1.104 0 -2 0.896 -2 2 s 0.896 2 2 2 h 22.252 c 1.104 0 2 -0.896 2 -2 s -0.896 -2 -2 -2 H 47 V 72.978 c 13.54 -1.026 24.245 -12.363 24.245 -26.16 v -6.505 C 71.245 39.208 70.35 38.312 69.245 38.312 z"
					transform="matrix(1 0 0 1 0 0)"
                    strokeLinecap="round"
				/>
			</g>
		</svg>
	);
};

export default Microphone;
