import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { VideoPlayer } from "./VideoPlayer";
import { Journey, ProjectDetails } from "./data";
import ThumbnailIntro from "./Thumbnail/ThumbnailIntro";
import { APIRequestId, getUserIdFromURL, serverRequest } from "../Services/API";
import { Loader } from "../core/Loader";
import PopUp from "../core/PopUp/PopUp";
import { ModeratedQuiz } from "./ModeratedQuiz";
import { ConfirmPopUp } from "./ConfirmPopUp";
import { JourneyPlayer } from "./JourneyPlayer";
import VideoThumbnail from "./VideoThumbnail/VideoThumbnail";
import { Helmet } from "react-helmet";
import "./ChatBox.scss";

const providerData: {
  projectData: ProjectDetails | null;
  APIRequestId: string;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
} = { projectData: null, APIRequestId, userName: "", setUserName: () => null };

export const ProjectDataContext = createContext(providerData);

interface ChatBoxProps {
  className?: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({ className }) => {
  const [stageData, setStageData] = useState<{
    data: Journey[];
    name: string;
  } | null>();
  const [currentStageData, setCurrentStageData] = useState<Journey | null>(
    null
  );
  const [isJourneyPlaying, setIsJourneyPlaying] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<ProjectDetails | null>(null);
  const [isPopUp, setIsPopUp] = useState<boolean>(false);
  const [replyAnswer, setReplyAnswer] = useState<string>("");
  const [isConfirmPopUp, setIsConfirmPopUp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiRequestId, setApiRequestId] = useState<string>("");
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [mainData, setMainData] = useState(null);
  const [allStageData, setAllStageData] = useState<{
    data: Journey[];
    name: string;
  } | null>();

  const [isprojectSpecificChanges, setisprojectSpecificChanges] =
    useState(false);

  const [tNcText, settNcText] = useState(null);

  const [lastQuizQuestionChecker, setLastQuizQuestionChecker] = useState(0);

  const saveJourneyData = useCallback((data: any) => {
    const requestId = uuidv4();
    setApiRequestId(requestId);
    setIsJourneyPlaying(true);
    // console.log("data", data);
    // console.log(
    //   "projectData?.videoProgressBarStages",
    //   projectData?.videoProgressBarStages
    // );

    setAllStageData(data);
    setStageData(data);

    setCurrentStageData(data.data[0]);
  }, []);

  //const respd = projectData?.videoProgressBarStages;
  //console.log("respd", respd);

  const stageType = useMemo(() => {
    if (projectData?.type === "Standalone") {
      return "standAloneJourney";
    } else if (stageData?.data.length === 1) {
      return "Faq";
    } else {
      return "Journey";
    }
  }, [projectData?.type, stageData?.data.length]);

  const getData = useCallback(
    async (text: string) => {
      // API request
      const userData = getUserIdFromURL();
      setIsLoading(true);
      const data = await serverRequest.post<any>("chatgptApi", {
        question: text,
        userId: userData?.userId,
        projectName: userData?.projectName,
      });
      setIsLoading(false);
      if (data?.data) {
        console.log("DATa data", data);
        saveJourneyData(data);
      }
    },
    [saveJourneyData]
  );

  const onClose = useCallback(() => {
    setIsPopUp(false);
  }, []);

  const handleBackToHome = useCallback((action: "yes" | "no") => {
    if (action === "yes") {
      setIsJourneyPlaying(false);
      setStageData(null);
      setCurrentStageData(null);
    }
    setIsConfirmPopUp(false);
  }, []);

  const resetJourney = useCallback(() => {
    setIsJourneyPlaying(false);
    setCurrentStageData(null);
    setShowPopUp(true);
    if (stageData?.data) setCurrentStageData(stageData.data[0]);
  }, [stageData?.data]);

  const backToHome = useCallback(() => {
    setIsConfirmPopUp(true);
  }, []);

  const saveEngagementData = useCallback(
    (nextStageData: Journey, tagData?: any) => {
      console.log("window.href", window.location.href);
      const data: any = {
        senderid: APIRequestId,
        projectName: projectData?.projectName ?? "",
        //projectUrl: projectData?.projectUrl ?? "",
        projectUrl: window.location.href,
        intentName: stageData?.name ?? "",
        userMessage: replyAnswer ?? "",
        type: stageType,
        userId: projectData?.userId ?? "",
        // botMessage: {
        //   stage: nextStageData?.stage ?? "",
        //   video: nextStageData?.video ?? "",
        //   text: nextStageData?.text ?? "",
        //   ReplyType: nextStageData?.ReplyType ?? "",
        // },
        botMessage: nextStageData,
      };
      if (tagData) {
        data["model"] = {
          tag: tagData,
        };
      }
      serverRequest.post<any>("saveData/engagementData/", data);
      // console.log("nextStageData?.ReplyType", nextStageData?.ReplyType);
      // if (
      //   !(
      //     nextStageData?.ReplyType == "videoInput" ||
      //     nextStageData?.ReplyType == "voiceInput"
      //   )
      // ) {
      //   serverRequest.post<any>("saveData/engagementData/", data);
      // }
    },
    [
      projectData?.projectName,
      projectData?.projectUrl,
      projectData?.userId,
      replyAnswer,
      stageData?.name,
      stageType,
    ]
  );

  function getCategory(
    answerArr: string | string[],
    categoryArr: string[][]
  ): string {
    // Ensure answerArr is an array
    if (!Array.isArray(answerArr)) {
      answerArr = [answerArr];
    }

    let categoryCounts: number[] = new Array(categoryArr.length).fill(0); // Initialize counts for each category
    (answerArr as string[]).forEach((answer) => {
      for (let i = 0; i < categoryArr.length; i++) {
        if (categoryArr[i].includes(answer)) {
          categoryCounts[i]++;
          break;
        }
      }
    });
    // Find the index of the category with the maximum count
    const maxCountIndex: number = categoryCounts.indexOf(
      Math.max(...categoryCounts)
    );
    // Return the category corresponding to the maxCountIndex
    return maxCountIndex >= 0 ? `Category ${maxCountIndex + 1}` : "Unknown";
  }

  function countIndices(data: any): { [key: number]: number } {
    const counts: { [key: number]: number } = {};
    data.forEach((item: any) => {
      counts[item.index] = (counts[item.index] || 0) + 1;
    });
    return counts;
  }

  function findMaxIndex(counts: { [key: number]: number }): number {
    let maxIndex = -1;
    let maxCount = -1;
    for (const index in counts) {
      if (counts.hasOwnProperty(index)) {
        if (counts[index] > maxCount) {
          maxCount = counts[index];
          maxIndex = parseInt(index);
        }
      }
    }
    return maxIndex;
  }

  const [forceUpdate, setForceUpdate] = useState(false);

  const handleNextStage = useCallback(
    (stageName?: string, tagData?: any) => {
      console.log("stageName0", stageName, "tagData", tagData);
      if (stageName) {
        const currentData = stageData?.data.find(
          (stage) => stage.stage === stageName
        );
        const currentDataIndex = stageData?.data.findIndex(
          (stage) => stage.stage === stageName
        );
        if (
          stageName === "end" ||
          (stageData && currentDataIndex === stageData?.data.length - 1)
        ) {
          setIsJourneyPlaying(false);
        } else if (
          currentData &&
          stageData &&
          currentDataIndex !== undefined &&
          currentDataIndex < stageData?.data.length
        ) {
          const seenStageDataArr: any = allStageData?.data?.map(
            (o: any, i: number) =>
              currentDataIndex === i ? { ...o, isSeen: true } : o
          );
          //console.log(window.location.search);
          //console.log("project", projectData);
          console.log("projectSpecificChangesresp", isprojectSpecificChanges);
          if (isprojectSpecificChanges == true) {
            console.log("inside");
            // this is 15 before
            //this is the index of last question, if index is 16 means last question is at stage17
            if (currentDataIndex == lastQuizQuestionChecker) {
              const indexCounts = countIndices(tagData?.quizData);
              const maxIndex = findMaxIndex(indexCounts);
              console.log("Index counts:", indexCounts);
              console.log("Index with maximum occurrences:", maxIndex);
              if (maxIndex >= 0 && maxIndex <= 2) {
                const newArray = [...stageData?.data];
                //this is the index of the result video will show
                const index = lastQuizQuestionChecker + 2;
                console.log("calculating this", index);

                if (index >= 0 && index < newArray.length) {
                  let videoUrl;
                  switch (maxIndex) {
                    // case 0:
                    //   videoUrl =
                    //     "https://hnylearning.com/awarenessHubBackend/static/savedVideos/Sales.mp4";
                    //   break;
                    // case 1:
                    //   videoUrl =
                    //     "https://hnylearning.com/awarenessHubBackend/static/savedVideos/CustomerService.mp4";
                    //   break;
                    // case 2:
                    //   videoUrl =
                    //     "https://hnylearning.com/awarenessHubBackend/static/savedVideos/GeneralAwareness.mp4";
                    case 0:
                      videoUrl =
                        "https://hnylearning.com/awarenessHubBackend/static/savedVideos/firstVid.mp4";
                      break;
                    case 1:
                      videoUrl =
                        "https://hnylearning.com/awarenessHubBackend/static/savedVideos/secondVid.mp4";
                      break;
                    case 2:
                      videoUrl =
                        "https://hnylearning.com/awarenessHubBackend/static/savedVideos/thirdVid.mp4";
                      break;
                    default:
                      videoUrl = "";
                  }
                  newArray[index] = {
                    ...newArray[index],
                    video: videoUrl,
                  };
                  setStageData((prevState: any) => ({
                    ...prevState,
                    data: newArray,
                  }));
                  // Trigger a re-render by updating a dummy state variable
                  setForceUpdate((prev: any) => !prev);
                  //console.log("Updated stageData:", stageData);
                  //console.log("Updated stageData:", stageData?.data[17].video);
                }
              }
            }
          }

          setAllStageData({
            data: seenStageDataArr,
            name: stageData.name,
          });

          setCurrentStageData(stageData?.data[currentDataIndex + 1]);
          saveEngagementData(stageData?.data[currentDataIndex], tagData);
        }
      }
    },
    [stageData, saveEngagementData]
  );

  const handlePrevStage = useCallback(
    (stageName?: string) => {
      if (stageName) {
        const currentData = stageData?.data.find(
          (stage) => stage.stage === stageName
        );
        const currentDataIndex = stageData?.data.findIndex(
          (stage) => stage.stage === stageName
        );
        if (
          currentData &&
          stageData &&
          currentDataIndex !== undefined &&
          currentDataIndex < stageData?.data.length
        ) {
          setCurrentStageData(stageData?.data[currentDataIndex - 1]);
        }
      }
    },
    [stageData]
  );

  const onAudioEnd = useCallback(
    (audioText?: string) => {
      if (audioText) {
        getData(audioText);
      }
    },
    [getData]
  );

  const isPrevVisible = useMemo(() => {
    if (currentStageData) {
      const index = stageData?.data.findIndex(
        (item) => item.stage === currentStageData.stage
      );
      if (index === 0 || stageData?.data.length === 1) {
        return false;
      }
      return true;
    }
    return false;
  }, [currentStageData, stageData]);

  const getInitialData = useCallback(async () => {
    const userData = getUserIdFromURL();
    if (userData) {
      const data = await serverRequest.post<{
        status: "success";
        data?: ProjectDetails;
      }>("getData/projectData", {
        userId: userData?.userId,
        projectName: userData?.projectName,
      });
      const engagementData = {
        senderid: APIRequestId,
        projectName: data.data?.projectName ?? "",
        projectUrl: data.data?.projectUrl ?? "",
        intentName: data.data?.intentName ?? "FAQ_or_Video_Data",
        userMessage: "getStarted",
        type:
          data?.data?.type === "Standalone" ? "standAloneJourney" : "Journey",
        userId: projectData?.userId ?? "",
        botMessage: {
          stage: "Stage0",
          text: data.data?.title ?? "",
          video: "",
          ReplyType: "<start>",
        },
      };
      if (projectData?.userId) {
        serverRequest.post<any>("saveData/engagementData/", engagementData);
      }
      if (data?.data) {
        console.log("Main Data from Server", data.data);
        const subdata = data.data;
        const videodata = subdata.videoProgressBarStages;
        setMainData(videodata);
        setProjectData(data.data);
        const projectSpecificChangesresp = subdata.projectSpecificChanges;
        const tNcTextresp = subdata.tNcText;
        console.log(
          "Main Data from projectSpecificChangesresp",
          projectSpecificChangesresp
        );
        setisprojectSpecificChanges(projectSpecificChangesresp);
        settNcText(tNcTextresp);
        const quizcheckerresp = subdata.quizchecker;
        console.log("Main Data from quizcheckerresp", quizcheckerresp);
        setLastQuizQuestionChecker(quizcheckerresp);
        if (
          data?.data.moderatedQuiz.toString().toLowerCase().includes("true")
        ) {
          setIsPopUp(true);
        } else {
          setIsPopUp(false);
        }
      }
      if (data?.data?.type === "Standalone") {
        saveJourneyData({
          data: data.data?.standAloneJourneyData,
          name: "journeyData",
        });
      }
    }
  }, [projectData?.userId, saveJourneyData]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    setShowPopUp(true);
  }, []);

  const contextData = useMemo(() => {
    return {
      projectData: projectData,
      APIRequestId,
      userName,
      setUserName,
    };
  }, [projectData, userName]);

  return (
    <div className={className}>
      <Helmet>
        {/* Set the title dynamically */}
        <link rel="icon" href={projectData?.logo1} type="image/png"></link>
        <title>{projectData?.brandName ?? "Awareness Hub"}</title>

        {/* Set the meta description dynamically */}
        <meta
          name="description"
          content={projectData?.title ?? "Awareness Hub"}
        />

        <meta
          property="og:title"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta
          property="og:description"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta property="og:image" content={projectData?.logo1} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={projectData?.projectUrl} />
        <meta
          property="og:site_name"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card */}
        <meta
          name="twitter:card"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta
          name="twitter:title"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta
          name="twitter:description"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta name="twitter:image" content={projectData?.logo1} />
        <meta name="twitter:url" content={projectData?.projectUrl} />

        {/* LinkedIn */}
        <meta
          property="og:article:author"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta
          property="og:article:section"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta
          property="og:article:published_time"
          content={new Date().toLocaleDateString()}
        />
        <meta
          property="og:article:modified_time"
          content={new Date().toLocaleDateString()}
        />
      </Helmet>
      <div
        className="chatbox-fixed blur"
        style={{ backgroundImage: `url(${projectData?.thumbnailUrl ?? ""})` }}
      ></div>
      <div className="chatbox-fixed">
        <ProjectDataContext.Provider value={contextData}>
          <div className={`chatbox-container`}>
            {isPopUp && (
              <PopUp>
                <ModeratedQuiz
                  onClose={onClose}
                  projectName={projectData?.projectName}
                />
              </PopUp>
            )}
            {projectData ? (
              <>
                {projectData?.type === "Standalone" ? (
                  showPopUp ? (
                    <VideoThumbnail
                      thumbnail={projectData?.thumbnailUrl ?? ""}
                      title={projectData?.title}
                      brandName={projectData?.brandName}
                      handleSubmit={() => setShowPopUp(false)}
                    />
                  ) : (
                    <>
                      <div className="pill-container">
                        <div style={{ color: "white" }} className="custom-pill">
                          Chatbox JourneyPlayer
                        </div>
                      </div>
                      <JourneyPlayer
                        stageData={mainData}
                        allStageData={allStageData}
                        journeyData={currentStageData}
                        onAudioEnd={onAudioEnd}
                        handleNextStage={handleNextStage}
                        handlePrevStage={handlePrevStage}
                        isPrevVisible={isPrevVisible}
                        thumbnail={projectData?.thumbnailUrl ?? ""}
                        backToHome={resetJourney}
                        replyAnswer={replyAnswer}
                        setReplyAnswer={setReplyAnswer}
                        loading={isLoading}
                        showPopUp={showPopUp}
                        setShowPopUp={setShowPopUp}
                        title={projectData?.title}
                        brandName={projectData?.brandName}
                        isQuizJourney={projectData?.intentName
                          ?.toLowerCase()
                          .includes("quiz")}
                      />
                    </>
                  )
                ) : (
                  <>
                    {isConfirmPopUp && (
                      <PopUp>
                        <ConfirmPopUp onSubmit={handleBackToHome} />
                      </PopUp>
                    )}
                    {isJourneyPlaying ? (
                      <div className="video-container">
                        <div className="pill-container">
                          <div
                            style={{ color: "white" }}
                            className="custom-pill"
                          >
                            Chatbox VideoPlayer
                          </div>
                        </div>
                        <VideoPlayer
                          journeyData={currentStageData}
                          onAudioEnd={onAudioEnd}
                          handleNextStage={handleNextStage}
                          handlePrevStage={handlePrevStage}
                          isPrevVisible={isPrevVisible}
                          thumbnail={projectData?.thumbnailUrl ?? ""}
                          backToHome={backToHome}
                          replyAnswer={replyAnswer}
                          setReplyAnswer={setReplyAnswer}
                          loading={isLoading}
                          showPopUp={showPopUp}
                          setShowPopUp={setShowPopUp}
                        />
                      </div>
                    ) : (
                      <ThumbnailIntro
                        className={isPopUp ? " blur" : ""}
                        onAudioEnd={onAudioEnd}
                        imageURL={projectData?.thumbnailUrl ?? ""}
                        introMessage={projectData?.title}
                        brandName={projectData?.brandName}
                        loading={isLoading}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </ProjectDataContext.Provider>
      </div>
    </div>
  );
};

export default ChatBox;
