import LoadingIcon from "../../icons/LoadingIcon";
import './loader.scss';

interface LoaderProps {
	className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className }) => {
	return (
		<div className={`loader${className? ` ${className}`:""}`}>
			<div className="icon-flex">
				<LoadingIcon width={40} height={40}/>
			</div>
		</div>
	);
};

export default Loader;
