import "./thumbnail.scss";

interface ThumbnailProps {
	className?: string;
	imageURL: string;
    children: JSX.Element
}

const Thumbnail: React.FC<ThumbnailProps> = ({ imageURL, className, children }) => {
	return (
		<div className={`thumbnail-container${className ? " " + className : ""}`}>
			<div className="thumbnail" style={{backgroundImage: `url(${imageURL})`}}>
                {children}
            </div>
		</div>
	);
};

export default Thumbnail;
