import { useCallback, useState, KeyboardEvent, useContext } from "react";
import { serverRequest } from "../../Services/API";
import { Loader } from "../../core";
import "./moderatedQuiz.scss";
import { ProjectDataContext } from "../ChatBox";

interface ModeratedQuizProps {
	className?: string;
	projectName?: string;
	onClose?: () => void;
}

const emailLabel = "Enter your email id";

const ModeratedQuiz: React.FC<ModeratedQuizProps> = ({ className, onClose, projectName }) => {
	const [userEmail, setUserEmail] = useState<string | undefined>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const { projectData, APIRequestId, setUserName } = useContext(ProjectDataContext);
	const [isOTPEnabled, setIsOTPEnabled] = useState<boolean>(false);
	const [userOTP, setUserOtp] = useState<string | undefined>("");

	const onEmailChange = useCallback((event?: React.ChangeEvent<HTMLInputElement>) => {
		setUserEmail(event?.target.value);
		setError("");
	}, []);

	const saveEngagementData = useCallback(() => {
		const data = {
			senderid: APIRequestId,
			projectName: projectData?.projectName ?? "",
			projectUrl: projectData?.projectUrl ?? "",
			intentName: projectData?.intentName ?? "",
			userMessage: emailLabel,
			type: projectData?.type === "Standalone" ? "Standalone" : "Journey",
			userId: projectData?.userId ?? "",
			botMessage: {
				stage: "ModerateAnswer",
				text: userEmail,
				video: "",
				ReplyType: "moderateEmail"
			}
		};
		serverRequest.post<any>("saveData/engagementData/", data);
	}, [
		APIRequestId,
		projectData?.intentName,
		projectData?.projectName,
		projectData?.projectUrl,
		projectData?.type,
		projectData?.userId,
		userEmail
	]);

	const generateOTP = useCallback(async () => {
		try {
			setIsLoading(true);
			const userData = { email: userEmail };
			const otp = await serverRequest.post<{
				status: string;
				message: string;
			}>("helper/generateOtp", userData);
			setIsLoading(false);
			if (otp.status === "success") {
				setIsOTPEnabled(true);
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}, [userEmail]);

	const onSubmit = useCallback(async () => {
		if (userEmail) {
			try {
				console.log({ projectData });
				setIsLoading(true);
				const saveModeratedData = await serverRequest.post<{
					status: string;
					userFound: boolean;
					user_Name: string;
				}>("getData/moderatedUserSearch/", {
					projectName: projectName,
					userMailId: userEmail //"1011770@icicilombard.com"
				});

				setIsLoading(false);
				if (saveModeratedData?.userFound) {
					setUserName(saveModeratedData.user_Name);
					saveEngagementData();
					generateOTP();
				} else {
					setError("User not found, please try again.");
				}
			} catch (error) {
				console.error(error);
				setIsLoading(false);
				alert("Something went wrong on server, please try later!");
			}
		}
	}, [userEmail, projectData, projectName, setUserName, saveEngagementData, generateOTP]);

	const onEnter = useCallback(
		(event?: KeyboardEvent<HTMLInputElement>) => {
			if (event?.key === "Enter") {
				onSubmit?.();
			}
		},
		[onSubmit]
	);

	const verifyOtp = useCallback(async () => {
		const data = {
			email: userEmail,
			otp: userOTP
		};
		setIsLoading(true);
		try {
			const verifyOtpResponse = await serverRequest.post<{
				status: string | "failure";
				message: string;
			}>("helper/validateOtp", data);
			setIsLoading(false);
			console.log({ verifyOtpResponse });
			if (verifyOtpResponse.status === "failure") {
				setError(verifyOtpResponse?.message ?? "Invalid OTP");
			} else {
				onClose?.();
			}
		} catch (error) {
			setIsLoading(false);
		}
	}, [onClose, userEmail, userOTP]);

	const onOTPChange = useCallback((event?: React.ChangeEvent<HTMLInputElement>) => {
		setUserOtp(event?.target.value);
		setError("");
	}, []);

	const onOTPEnter = useCallback(
		(event?: KeyboardEvent<HTMLInputElement>) => {
			if (event?.key === "Enter") {
				verifyOtp();
			}
		},
		[verifyOtp]
	);

	const handleBack = useCallback(() => {
		setIsOTPEnabled(false);
		setError("");
		setUserOtp("");
	}, []);

	return (
		<>
			{isLoading && <Loader />}
			<div className={`moderated-quiz${className ? " " + className : ""}`}>
				<div className="form-control">
					<label>{isOTPEnabled ? "Please Enter OTP" : emailLabel}</label>
					{isOTPEnabled ? (
						<input
							placeholder="Enter OTP here..."
							onChange={onOTPChange}
							type="number"
							value={userOTP}
							onKeyDown={onOTPEnter}
						/>
					) : (
						<input
							placeholder="Enter here..."
							onChange={onEmailChange}
							type="text"
							value={userEmail}
							onKeyDown={onEnter}
						/>
					)}
					{error && <p className="error">{error}</p>}
				</div>
				<div className="form-control text-center">
					{isOTPEnabled ? (
						<div>
							<button className="btn dark-btn" onClick={() => handleBack}>
								Back
							</button>
							<button className="btn dark-btn" onClick={verifyOtp}>
								Verify
							</button>
						</div>
					) : (
						<button className="btn dark-btn" onClick={onSubmit}>
							Submit
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default ModeratedQuiz;
