import { Icon } from "../types/app.data";

const Unmute: React.FC<Icon> = ({ width, height, color, className, onClick }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
            onClick={onClick}
            className={className??""}
			height={height}
			viewBox="0 0 30 35"
			fill={color}
		>
			<path
				d="M4.28571 13.125H0V21.875H4.28571L12.8571 28.4375H15V6.5625H12.8571L4.28571 13.125ZM18.0305 14.4064C17.6121 13.9793 16.9337 13.9793 16.5152 14.4064C16.0968 14.8335 16.0968 15.5261 16.5152 15.9532C17.3521 16.8075 17.3521 18.1925 16.5152 19.0468C16.0968 19.4739 16.0968 20.1665 16.5152 20.5936C16.9337 21.0207 17.6121 21.0207 18.0305 20.5936C19.7041 18.885 19.7041 16.1149 18.0305 14.4064ZM21.0609 11.3128C20.6426 10.8857 19.9641 10.8857 19.5457 11.3128C19.1273 11.7399 19.1273 12.4325 19.5457 12.8596C22.0562 15.4224 22.0562 19.5776 19.5457 22.1404C19.1273 22.5675 19.1273 23.2601 19.5457 23.6872C19.9641 24.1143 20.6426 24.1143 21.0609 23.6872C24.4083 20.2701 24.4083 14.7299 21.0609 11.3128ZM24.0914 8.21922C23.6729 7.79208 22.9945 7.79208 22.5761 8.21922C22.1577 8.64633 22.1577 9.33888 22.5761 9.76603C26.7603 14.0374 26.7603 20.9626 22.5761 25.234C22.1577 25.6611 22.1577 26.3537 22.5761 26.7808C22.9945 27.2079 23.6729 27.2079 24.0914 26.7808C29.1124 21.6551 29.1124 13.3448 24.0914 8.21922Z"
				fill={color}
			/>
		</svg>
	);
};

export default Unmute;
