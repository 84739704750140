import PopUp from "../../../core/PopUp/PopUp";
import "./videoPlayPopUp.scss";

interface VideoPlayPopUpProps {
	className?: string;
	handleSubmit: () => void;
	onCancel: () => void;
}

const VideoPlayPopUp: React.FC<VideoPlayPopUpProps> = ({ className, handleSubmit, onCancel }) => {
	return (
		<div className={`video-play-sec${className ? +" " + className : ""}`}>
			<div className="video-play-rel">
				<PopUp closePopUp={onCancel}>
					<>
						<div className="message-sec">
							<h3 className="text-center">Are you 18+?</h3>
						</div>
						<div className="pop-action text-center">
							<button className="btn dark-btn" onClick={handleSubmit}>
								Yes
							</button>
							<button className="btn btn-rounded" onClick={onCancel}>
								No
							</button>
						</div>
					</>
				</PopUp>
			</div>
		</div>
	);
};

export default VideoPlayPopUp;
