import { useState } from "react";

export const useInput = () => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const onFocus = () => {
		setIsFocused(true);
	};
	const onBlur = () => {
		setIsFocused(false);
	};

	return { isFocused, onBlur, onFocus };
};
