import { Icon } from "../types/app.data";

const Prev: React.FC<Icon> = ({ width, height, color, onClick, className }) => {
	return (
		<svg
			className={className ?? ""}
			height={height ?? "20px"}
			id="Layer_1"
			version="1.1"
			viewBox="0 0 512 512"
			width={width ?? "20px"}
			fill={color ?? "fff"}
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
		>
			<polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
		</svg>
	);
};

export default Prev;
