import ButtonReply from '../ReplyType/ButtonReply'
import DateReply from '../ReplyType/DateReply'
import DescriptionReply from '../ReplyType/DescriptionReply'
import EmailReply from '../ReplyType/EmailReply'
import EndReply from '../ReplyType/EndReply'
import JourneyEndReply from '../ReplyType/JourneyEndReply'
import MobileReply from '../ReplyType/MobileReply'
import MobileSkipReply from '../ReplyType/MobileSkipReply'
import QuizButtonReply from '../ReplyType/QuizButton'
import TextReply from '../ReplyType/TextReply'
import VoiceInputReply from '../ReplyType/VoiceInputReply'
import { Journey } from '../data'
import VideoInputReply from '../ReplyType/VideoInputReply'
import NameReply from '../ReplyType/NameReply'
interface JourneyReplyTypeProps {
  journeyData: Journey | null
  onAudioEnd: (audioText?: string) => void
  loading: boolean
  replyAnswer: string
  onAnswerChange: (answer: string) => void
  onSubmit: () => void
  error: string
  setReplyAnswer: React.Dispatch<React.SetStateAction<string>>
  type?: 'StandAlone'
  setQuizData?: React.Dispatch<
    React.SetStateAction<
      | {
          stage?: string
          scored?: number
          actualScore?: number
          answer: string
        }[]
      | undefined
    >
  >
  quizData?:
    | {
        stage?: string
        scored?: number
        actualScore?: number
        answer: string
      }[]
    | undefined
}

const JourneyReplyType: React.FC<JourneyReplyTypeProps> = ({
  journeyData,
  onAudioEnd,
  loading,
  replyAnswer,
  onAnswerChange,
  onSubmit,
  error,
  setReplyAnswer,
  type,
  quizData,
  setQuizData
}) => {
  // console.log(
  //   "journeyData?.ReplyType",
  //   journeyData?.ReplyType.toLowerCase().trim()
  // );
  console.log('journeyData', journeyData)
  switch (journeyData?.ReplyType.toLowerCase().trim()) {
    case 'end':
      setReplyAnswer('')
      return type === 'StandAlone' ? (
        <JourneyEndReply
          journeyData={
            journeyData ?? 'You have successfully completed the Journey!'
          }
          quizData={quizData}
        />
      ) : (
        <EndReply
          onAudioEnd={onAudioEnd}
          label={journeyData?.text ?? 'Thank you for your query!'}
          loading={loading}
        />
      )
    case 'mobile':
      return (
        <MobileReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'mobileskip':
      return (
        <MobileSkipReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'text':
      return (
        <TextReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'name':
      return (
        <NameReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'voiceinput':
      return (
        <VoiceInputReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
          //setReplyAnswer={setReplyAnswer}
        />
      )
    case 'videoinput':
      return (
        <VideoInputReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          stage={journeyData.stage}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'email':
      return (
        <EmailReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'button':
      return (
        <ButtonReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          buttons={journeyData?.buttons}
          label={journeyData?.text ?? ''}
          labelheader={journeyData?.textHeader ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'date':
      return (
        <DateReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
          validation={journeyData?.validation}
        />
      )
    case 'quizbutton':
      return (
        <QuizButtonReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          buttons={journeyData?.buttons}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          correctAnswer={journeyData?.correctAnswer}
          score={journeyData?.score}
          stage={journeyData?.stage}
          setQuizData={setQuizData}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
          error={error}
        />
      )
    case 'description':
      return (
        <DescriptionReply
          value={replyAnswer}
          onChange={onAnswerChange}
          onSubmit={onSubmit}
          collectiveIntelligenceData={journeyData?.collectiveIntelligenceData}
          options={journeyData?.options}
          collectiveIntelligence={journeyData?.collectiveIntelligence}
          stage={journeyData?.stage}
          labelheader={journeyData?.textHeader ?? ''}
          label={journeyData?.text ?? ''}
          error={error}
          relatedStage={journeyData?.relatedStage}
          quizData={quizData}
          ModelType={journeyData?.ModelType ?? ''}
          isModel={journeyData?.isModel ?? ''}
        />
      )
    default:
      return null
  }
}

export default JourneyReplyType
